interface CognitoAttributes {
  'email': string
  'email_verified': boolean
  'name': string
  'phone_number': string
  'sub': string
  'custom:supportUserPrincipal': string
}
interface CognitoSignInUserSession {
  accessToken: {
    jwtToken: string
  }
  idToken: {
    jwtToken: string
  }
  refreshToken: {
    token: string
  }
}

export interface UserType {
  username?: string
  userDataKey?: string
  signInUserSession?: CognitoSignInUserSession
  attributes?: CognitoAttributes
  at_hash?: string
  aud?: string
  auth_time?: number
  email?: string
  email_verified?: boolean
  exp?: number
  iat?: number
  identities?: any[]
  iss?: string
  jti?: string
  name?: string
  phone_number?: string
  phone_number_verified?: boolean
  sub?: string
  token_use?: string
}

export enum USER_ROLES {
  MAKER = 'MAKER',
  CHECKER = 'CHECKER',
  VIEWER = 'VIEW_ONLY',
  SUPER_MAKER = 'SUPER_MAKER',
  SUPER_CHECKER = 'SUPER_CHECKER'
}

export enum USER_ROLES_DISPLAY {
  MAKER = 'Maker',
  CHECKER = 'Checker',
  VIEW_ONLY = 'Viewer',
  SUPER_MAKER = 'Super Maker',
  SUPER_CHECKER = 'Super Checker'
}

export interface IUserListInResponseGetAll {
  emailAddress: string
  id: string
  name: string
  phoneNo: string
}

export enum LIST_MODULE {
  CORPORATES = 'corporates',
  HUB_ACCOUNTS = 'hub_accounts',
  SPOKE_ACCOUNTS = 'spoke_accounts',
  E_MONEY = 'e_money',
  SANCTIONED_COUNTRIES = 'sanctioned_countries',
  MANUAL_TRANSACTION = 'manual_transaction',
  INTERNAL_ACCOUNT = 'internal_account',
  CALL_REQUESTS = 'call_request',
  SALARY_PROCESSING = 'wps',
  WPS_FILE = 'wps_file',
  NON_WPS = 'non_wps',
  MONTHLY_SALARY_PAYMENT_REPORT = 'salary_payment_report',
  AUDIT_TRAIL_REPORT = 'audit_trail_report',
  SALARY_DISBURSAL_REPORT = 'salary_disbursal_report',
  LIST_OF_ACCOUNTS_TAGGED_TO_A_CORPORATE_REPORT = 'list_account_tagged_report',
  RECONCILIATION_REPORT_DAILY_RECONCILIATION_REPORT_BY_COUNTRY = 'daily_reconciliation_report',
  RECONCILIATION_REPORT_DAILY_INTERNAL_AND_PNL_BALANCE_REPORT_BY_COUNTRY = 'daily_internal_report',
  SUSPICIOUS_TRANSACTION_REPORT = 'suspicious_transaction_report',
  AUDIT_REPORT_FOR_IP_AND_DEVICE_ID = 'audit_report_device_report',
  KYC_AND_NAME_SCREENING_FAILURE_REPORT = 'kyc_and_name_screening_failure_report',
  CARD_STATUS_REPORT = 'card_status_report',
  CARD_RECONCILIATION_REPORT = 'card_reconciliation_report',
  TRADE_LICENSE_EXPIRY_REPORT = 'trade_license_expiry_report',
  LOW_SALARY_THAN_AVERAGE = 'reports_transactions',
  HIGH_VOLUME_OF_POS_TRANSACTIONS = 'reports_pos_transactions',
  CORPORATE_SUSPENSE_ACCOUNT_BALANCE_REPORT = 'corporate_balance_report',
  CORPORATE_PRICING_SYNTHESIS_REPORT = 'report_corporate_pricing_synthesis',
  INTERNAL_SUSPENSE_ACCOUNT_BALANCE_REPORT = 'internal_account_balance_report',
  USER_GROUP = 'user_group',
  USER = 'user',
  FX_RATES = 'fx_rates',
  LIMIT_MANAGEMENT = 'limit_management',
  FUND_TRANSFER_FEES = 'fund_transfer_fees',
  TRANSACTION_FEES = 'transaction_fees',
  SITE_SETTINGS = 'site_settings',
  NOTIFICATION_SMS = 'notification_sms',
  DASHBOARD = 'dashboard',
  CUSTOMERS = 'customers',
  ENQUIRY = 'enquiry',
  NOTIFICATIONS = 'notifications',
  SUPPORT = 'support',
  REPORTS = 'reports',
  ADMIN = 'admin',
  SETTINGS = 'settings',
  FILE_MANAGEMENT = 'file_management',
  REQUEST_MANAGEMENT = 'request_management',
  WPS_EXCEPTIONAL_QUEUE = 'wps_exceptional_queue',
  NON_WPS_EXCEPTIONAL_QUEUE = 'non_wps_exceptional_queue',
  PROCESSED_RETURN_REPORT_BY_CORPORATE_AND_BY_RFA_FILE = 'processed_return_report',
  WPS_SUMMARY_REPORT_BY_CORPORATE_AND_BY_FILE = 'reports_audit_wps_payment',
  OVERDUE_CBFT_TRANSACTION_REPORT = 'overdue_transaction_report',
  EDIT_HUB_USER_DETAILS = 'edit_hub',
  EDIT_SPOKE_USER_DETAILS = 'edit_spoke',
  HUB_MANAGEMENT = 'hub_management',
  SPOKE_MANAGEMENT = 'spoke_management',
  EXPIRED_EMIRATES_ID_CARD_REPORT_FOR_HUB_USERS = 'worker_eid_expired_report',
  DAILY_CBFT_ACCOUNT_BALANCE_SWEEPING_REPORT = 'report_sweep_account_balance',
  CORPORATE_PRICING = 'corporate_pricing_management',
  SAVING_GOAL_ACCOUNTS_REPORT = 'saving-goal-report',
  FREQUENT_CHANGES_OF_BENEFICIARIES_REPORT = 'report_frequent_beneficiaries_change',
  CONFIGURATION = 'configuration',
  NEW_TRANSACTIONS_FOR_INOPERATIVE_ACCOUNTS_REPORT = 'report_inoperative_account',
  HUB_ACCOUNT_UAE_REPORT = 'report_hub_account',
  FRAUD_RULES_REPORT = 'card_fraud_report',
  LIST_OF_CROSS_BORDER_FUND_TRANFERS_REPORT = 'cross_border_fund_transfers_report',
  MONTHLY_CORPORATE_ONBOARDING_SUMMARY_REPORT = 'monthly_corporate_onboarding_summary_report',
  CARD_MANAGEMENT = 'card_management_1',
  GENERATED_CARD = 'generate_card',
  REPLACE_CARD = 'replace_card_1',
  GENERATED_CARD_NOW = 'generate_card_now',
  GENERATED_CARD_DETAIL = 'card_detail',
  GENERATED_CARD_DETAIL_NOW = 'generate_card_now_1',
  REPLACE_CARD_NOW = 'replace_card_now',
  REPLACE_CARD_DETAIL = 'card_detail_1',
  REPLACE_CARD_DETAIL_NOW = 'replace_card_now_1',
  LIST_OF_INVOICE_REPORT = 'invoice_report',
  NATIONALITY_REPORT = 'national_report',
  UNCLAIMED_FLAG_REPORT = 'report_unclaimed_flag_report'
}
